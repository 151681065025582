import type { CameraExid } from "./camera"
import type { ProjectExid } from "./project"

export enum CopilotMessageAuthor {
  Copilot = "copilot",
  User = "user",
}

export enum CopilotMessageType {
  Text = "text",
  Pdf = "pdf",
  Progress = "progress",
  Stop = "stop",
  Error = "error",
}

export type CopilotMessage = {
  content: string
  url?: string
  type: CopilotMessageType
  id?: number
  conversationId?: number
}

export enum CopilotStepId {
  SendToLLM = "send_to_llm",
  FunctionCallRequest = "function_call_request",
  ExecFunctionCall = "exec_function_call",
  SendToUser = "send_to_user",
}

export type UserConversations = CopilotConversation & {
  Message: CopilotMessage[]
}

export enum CopilotSocketEvent {
  ConversationStart = "conversation:start",
  ConversationCreated = "conversation:created",
  LLMMessageChunk = "llm:message:chunk",
  LLMMessageComplete = "llm:message:complete",
  UserMessage = "user:message",
  ContextError = "chat:contextError",
  ChatError = "chat:chatError",
  LLMRequestCancel = "llm:request:cancel",
  LLMRequestCanceled = "llm:request:canceled",
}

export type CopilotClientChatMessage = {
  id: number
  text: string
  chunks: CopilotMessage[]
  author: CopilotMessageAuthor
  isInitial?: boolean
}

export type CopilotConversation = {
  id: number
  user: string
  model: string
  context: string
  cameraExid: CameraExid
  projectExid: ProjectExid
  startDate: string
  endDate: string
  messagesCount: number
}

export enum CopilotProvider {
  Gemini = "gemini",
  ChatGPT = "chatgpt",
}

export enum CopilotSuggestion {
  UserManual = "user_manual",
  GateReport = "gate_report",
  SiteActivity = "site_activity",
  Weather = "weather",
}

export enum CopilotChatProvider {
  Gemini = "gemini",
  ChatGpt = "chatGpt",
  GeminiTimelapse = "geminiTimelapse",
}
